<div id="footer" class="sticky-footer bg_color footer-costume">
   <!-- Main -->
   <div class="container">
      <div class="row">
         <!-- <div class="col-md-4 col-sm-6">
            <img class="footer-logo" src="assets/images/puneresortlogo.png" alt="" width="80%">
         </div> -->
         <div class="col-md-4 col-sm-6 ">
            
            <!-- <p class="text-black">Morbi convallis bibendum urna ut viverra. Maecenas quis consequat libero, a feugiat eros. Nunc ut lacinia tortor morbi ultricies laoreet ullamcorper phasellus semper.</p> -->
            <div class="text-widget text-black">
              <!-- <strong> Office Address : </strong> <span class="text-black"  > {{propertyDetails.address}}</span> -->

                <!-- <br> -->
              <strong> Phone : </strong><span class="text-black">
                  <a  href="tel:+91-9130583132" class="text-black">+91 9130583132 </a> 

               </span><br>
              <strong> E-Mail : </strong> <span class="text-black"> 
                  <a  href="mailto:myresortspune@gmail.com" class="text-black" > myresortspune@gmail.com</a>

                </span><br>
            </div> 
            <!-- <a href="tel:+0800265663" > <img class="footer-logo" src="assets/images/call-banner.png" alt=""  height="80px"></a> -->
            <div class="mt-4">
               <a target="_blank" href="https://instagram.com/myresortspune?igshid=1ipj1x8it5m5r" class="btn btn-neutral btn-icon-only btn-round btn-lg" data-toggle="tooltip" data-original-title="Follow us" style="background-color:#FD1D1D ;color: white;border-color:#FD1D1D ;" > 
               <i class="fa fa-instagram"></i>
               </a>
               <a target="_blank" href=" https://www.facebook.com/BookMyResortsPune/" class="btn btn-neutral btn-icon-only btn-round btn-lg" data-toggle="tooltip" data-original-title="Like us" style="background-color: 	#3b5998; color: white;">
               <i class="fa fa-facebook-square"></i>
               </a>
             
               <a *ngIf="propertyDetails.twitter" target="_blank" href="{{propertyDetails.twitter}}" class="btn btn-neutral btn-icon-only  btn-lg btn-round" data-toggle="tooltip" data-original-title="Follow us">
               <i class="fa fa-twitter"></i>
               </a>
               <!-- <a target="_blank" href="https://github.com/" class="btn btn-neutral btn-icon-only btn-round btn-lg" data-toggle="tooltip" data-original-title="Star on Github">
               <i class="fa fa-github"></i>
               </a> -->
              
            </div>
         </div>
         <div class="col-md-4 col-sm-4 ">
            <ul class="footer-links">
               <!-- <li><a class="text-black" routerLink='/session/login'>Login</a></li>
               <li><a class="text-black" routerLink='/session/signup'>Sign Up</a></li>
               <li><a class="text-black" routerLink='/session/forgot-password'>Forgot Password</a></li> -->
             
               <li><a class="text-black" routerLink='/terms'>Terms & Conditions</a></li>
               <li><a class="text-black" routerLink='/privacy'>Privacy Policy </a></li>
               <li><a class="text-black" routerLink='/cancellation-policy'>Cancellation & Refund Policy</a></li>
               
              
            </ul>
         </div>
         <div class="col-md-4  col-sm-4">
            <ul class="footer-links">
               <!-- <li><a class="text-black" routerLink='/user-profile'>Profile</a></li> -->
               <li><a class="text-black" routerLink='/registration'>Add Listing</a></li>
              <li><a class="text-black" routerLink='/about-us'>About</a></li>
               <!-- <li><a class="text-black" routerLink='/posts'>Blog</a></li> -->
               
               <li><a class="text-black" routerLink='/contact-us'>Contact</a></li>
               <li><a class="text-black" routerLink='/help'>How To Create Booking ?</a></li>
               
             
            </ul>
         </div>
         <!-- <div class="col-md-2 col-sm-6 ">
            <h6 style="color: black;">Get The Mobile App</h6>
            <div class="row">
               <div class="col-12">
                  <a target="_blank" href="{{androidAppUrl}}"><img class="mb-05 applogo1" width="150px" src="assets/images/playdownload.png"  alt="" ></a>
       
            <a target="_blank" href="{{iosAppUrl}}"><img class=" mb-05 applogo2" width="150px"  src="assets/images/appdownload.png"  alt="" ></a>
               </div>
            
            </div>
         </div> -->
      </div>
      <!-- Copyright -->
      <div class="row">
         <div class="col-md-12">
            <div class="copyrights text-black">© 2022 My Resorts Pune. All Rights Reserved.</div>
         </div>
      </div>
   </div>
</div>

