import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { ANDROID_APP_URL, IOS_APP_URL } from 'src/app/app.component';
import { BusinessService } from 'src/app/services/business.service';
import { Logger } from 'src/app/services/logger.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './Footer.component.html',
  styleUrls: ['./Footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent {
  androidAppUrl: string;
  iosAppUrl: string;
  propertyDetails: any = {
    address: '',
    
  };
countryBase: string = environment.country;
parentOrganisationId = environment.parentOrganisationId;
  constructor(
    
    private businessService: BusinessService,
  ) {
    this.androidAppUrl = ANDROID_APP_URL;
    this.iosAppUrl = IOS_APP_URL;
  }
  ngOnInit(): void {
    
    this.getOrganisationDetailsById();
  }
  getOrganisationDetailsById() {

this.businessService.getPropertyDetailsById(this.parentOrganisationId).subscribe(
  (data) => {
    this.propertyDetails = data.body;
    Logger.log('propertyDetails ' + JSON.stringify(this.propertyDetails) );
  });

  }
}
