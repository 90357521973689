import { Routes } from '@angular/router';

import { AdminPanelLayoutComponent } from './layouts/adminPanel/AdminPanelLayout.component';
import { FrontendPanelLayoutComponent } from './layouts/frontendPanel/FrontendPanel.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

export const AppRoutes: Routes = [
//   {
//   path: '',
//   redirectTo: '',
//   pathMatch: 'full',
// },
 {
  path: '',
  component: FrontendPanelLayoutComponent,
  children: [{
    path: '',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
    // loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'list',
    loadChildren: () => import('./listing/listing.module').then(m => m.ListingModule)
  },
  {
    path: 'packages',
    loadChildren: () => import('./packages/packages.module').then(m => m.PackagesModule)
  },
  // {
  //   path: '',
  //   loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  // }
]
},
// {
//   path: 'user',
//   component: AdminPanelLayoutComponent,
//   children: [{
//     path: '',
//     loadChildren: () => import('./adminPages/admin.module').then(m => m.AdminModule)
//   }]
// },
// {
//   path: 'session',
//   component: AuthLayoutComponent,
//   children: [{
//     path: '',
//     loadChildren: () => import('./session/session.module').then(m => m.SessionModule)
//   }]
// }
];
