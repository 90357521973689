import { Injectable } from '@angular/core';

export interface Menu {
  state: string;
  name: string;
  type?: string;
  children?: Menu[];
}

const MENUITEMS = [
  {
    state: '/',
    name: 'Home',
    type: 'link',
    // children: [
    //   {state: 'version1', name: 'Home Version 1', type: 'link'},
    //   {state: 'version2', name: 'Home Version 2', type: 'link'}
    // ]
  },
  {
    // state: 'blog',
    state: 'packages',
    name:'Packages',
    type: 'sub',
   
    children: [
          //  {state: 'one-day-trip', name: 'One Day Trip', type: 'link'},
           {state: 'resorts', name: 'Resorts', type: 'link'},
           {state: 'private-villa', name: 'Private Villa / Farm House', type: 'link'},
           {state: 'holiday-packages', name: 'Holiday Packages', type: 'link'},
          //  {state: 'Real Estate ', name: 'Real Estate', type: 'link'}
        ]
  },
  // {
  //   // state: 'blog',
  //   state: 'Offers',
  //   name:'Offers',
  //   type: 'link',
  // },
  // {
  //   state: 'user',
  //   name: 'User Panel',
  //   type: 'sub',
  //   children: [
  //     {state: 'dashboard', name: 'Dashboard', type: 'link'},
  //     {state: 'messages', name: 'Messages', type: 'link'},
  //     {state: 'bookings', name: 'Bookings', type: 'link'},
  //     {state: 'reviews', name: 'Reviews', type: 'link'},
  //     {state: 'bookmarks', name: 'Bookmarks', type: 'link'},
  //     {state: 'list', name: 'My Listing', type: 'link'},
  //     {state: 'add-list', name: 'Add List', type: 'link'},
  //     {state: 'profile', name: 'Profile', type: 'link'}
  //   ]
  // },
  {state: 'about-us', name: 'About Us', type: 'link'},
  // {state: 'pricing', name: 'Pricing', type: 'link'},
  // {state: 'invoice', name: 'Invoice', type: 'link'},
  // {state: 'booking', name: 'Booking', type: 'link'},
  // {state: 'add-listing', name: 'Add Listing', type: 'link'},
  // {state: 'user-profile', name: 'Profile', type: 'link'},
  // {
    
  //   state: 'FAQs',
  //   name: 'FAQs',
  //   type: 'link',
  // },
  {state: 'partner-with-us', name: 'List your Property', type: 'link'},
  // {state: 'contact-us', name: 'Contact Us', type: 'link'},
  //  {
  //     state: 'session',
  //     name: 'Session',
  //     type: 'sub',
  //     children: [
  //        {state: 'login', name: 'Login', type: 'link'},
  //        {state: 'signup', name: 'Register', type: 'link'},
  //        {state: 'forgot-password', name: 'Forgot Password', type: 'link'},
  //        {state: 'coming-soon', name: 'Coming Soon', type: 'link'}
  //     ]
  //  }
];


@Injectable()
export class MenuItems {
  getAll() {
    return MENUITEMS;
  }
}
