<header class="header-global">
   <nav id="navbar-main" class="navbar navbar-main fixed-top navbar-expand-lg navbar-transparent navbar-light headroom">
      <div class="container-fluid">
         <div id="logo">
            <a [routerLink]="['/']"><img src="assets/images/puneresortlogo.png"  alt=""  class="logo" style="height: 100px;"></a>
         </div>
         <div >
         <h2 class="text-center text-heading mytext1 " style="font-family: sans-serif; color: #be444e;">My Resorts Pune</h2>
         </div>
         <!-- <h2 class="text-center display-2">{{Title}}</h2> -->
         <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar_global" aria-controls="navbar_global"
            aria-expanded="false" aria-label="Toggle navigation">
         <span class="navbar-toggler-icon"></span>
         </button>
         <div class="navbar-collapse collapse" id="navbar_global">
            <div class="navbar-collapse-header">
               <div class="row">
                  <div class="col-6 collapse-brand">
                     <!-- <div id="logo">
                        <a [routerLink]="['/']"><img class="img-fluid" src="assets/images/puneresortlogo.png" width="135" height="50" alt=""></a>
                     </div> -->
                     <div class="main-search-inner">
                     <h6 class="text-center ">{{Title}}</h6>
                     </div>
                  </div>
                  <div class="col-6 collapse-close">
                     <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbar_global"
                        aria-controls="navbar_global" aria-expanded="false" aria-label="Toggle navigation">
                     <span></span>
                     <span></span>
                     </button>
                  </div>
               </div>
            </div>
            <div class="navbar-nav navbar-nav-hover align-items-lg-center ml-auto">
               <ul class="m-0 p-0">
                  <app-menu></app-menu>
               </ul>
               
               <!-- <ul class="navbar-nav ml-lg-auto responsive" *ngIf="signedIn == false" >
                  <li class="nav-item dropdown">
                     <a class="nav-link dropdown-toggle" href="#" id="navbar-primary_dropdown_1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="assets/images/thumb-4.jpg" class="img-fluid rounded-circle shadow-lg mr-1" width="40" height="50" alt="">Ethan Moore</a>
                     <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbar-primary_dropdown_1">
                        <a class="dropdown-item" [routerLink]="['/user/profile']">My Account</a>
                        <a class="dropdown-item" [routerLink]="['/user/profile']">Settings</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" [routerLink]="['/session/logout']">Logout</a>
                     </div>
                  </li>
               </ul>
               <ul class="navbar-nav ml-lg-auto responsive" *ngIf="signedIn == true" >
                  
                     <a class="nav-link" [routerLink]="['/session/login']">Log in</a>
                     <a class="nav-link" [routerLink]="['/session/login']">Register</a>
                 
               </ul> -->
               <!-- <div class="header-widget">
                  <a [routerLink]="['/registration']" class="btn btn-neutral btn-icon btn-radius">Sign In <i class="fa fa-plus"></i></a>
               </div> -->
            </div>
         </div>
      </div>
   </nav>
</header>

