<div class="db-admin">
   <div class="dashboard-nav">
      <div class="dashboard-nav-inner">
         <ul data-submenu-title="Main">
            <li class="active"><a [routerLink]="['/user/dashboard']"><i class="fa fa-tachometer"></i> Dashboard</a></li>
            <li><a [routerLink]="['/user/messages']"><i class="fa fa-inbox"></i> Messages <span class="nav-tag messages">2</span></a></li>
            <li><a [routerLink]="['/user/bookings']"><i class=" fa fa-calendar"></i> Bookings</a></li>
         </ul>
         <ul data-submenu-title="Listings">
            <li><a [routerLink]="['/user/list']"><i class="fa fa-calendar-check-o"></i> My Stays</a></li>
            <li><a [routerLink]="['/user/reviews']"><i class="fa fa-star"></i> Reviews</a></li>
            <li><a [routerLink]="['/user/bookmarks']"><i class="fa fa-bookmark"></i> Bookmarks</a></li>
            <!-- <li><a [routerLink]="['/user/add-list']"><i class="fa fa-calendar-plus-o"></i> Add Listing</a></li> -->
         </ul>
         <ul data-submenu-title="Account">
            <li><a [routerLink]="['/user/profile']"><i class="fa fa-user"></i> My Profile</a></li>
            <li><a [routerLink]="['/home']"><i class="fa fa-sign-out"></i> Logout</a></li>
         </ul>
      </div>
   </div>
</div>
<a class="db-trigger" (click)="toggleMenu()">
<i class="fa fa-reorder"></i>
</a>

