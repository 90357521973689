import { Injectable } from "@angular/core";
import { Booking } from "./model/booking";
import { BusinessServiceTypes } from "./model/businessServiceTypes";
import { Payment } from "./model/payment";
import { PropertyServiceDTO } from "./model/PropertyServices";
import { Room } from "./model/room";
import { BusinessUser } from "./model/user";

const SLOTDATA = "slotdata";
const BOOKINGDATA = "booking";
const BOOKINGCITY = "bookingCity";
const BUSINESS_SERVICE = "businessservice";
const businessTypeGroupListDATA = "businessTypeGroupList";
const businessTypeListDATA = "businessTypeList";
const ROOMSDATA = "roomsData";
const PROPERTY_DETAILS = 'PROPERTY_DETAILS';
const PROPERTY_SERVICE_DATA = "PROPERTY_SERVICE_DATA";
const COUNTRY = "country";
const PAYMENT = "payment";
const PAYMENT2 = "payment2";
const PROPERTY = "property";
@Injectable()
export class TokenStorage {
  constructor() {}
  clearAll() {
    window.sessionStorage.clear();
  }
  clearBusiness() {
    window.sessionStorage.removeItem(BUSINESS_SERVICE);
    // window.sessionStorage.removeItem(BOOKSLOTDATA);
    window.sessionStorage.removeItem(BOOKINGDATA);
  }

  clearCountry() {
    window.sessionStorage.removeItem(COUNTRY);
  }
  public getProperty(): BusinessUser {
    return JSON.parse(localStorage.getItem(PROPERTY_DETAILS) as string);
  }
  public saveCountry(Country) {
    window.sessionStorage.removeItem(COUNTRY);
    if (Country !== null || Country !== undefined) {
      window.sessionStorage.setItem(COUNTRY, Country);
    } else {
      window.sessionStorage.setItem(COUNTRY, null);
    }
  }
  public getServiceData(): PropertyServiceDTO[] {
    return JSON.parse(sessionStorage.getItem(PROPERTY_SERVICE_DATA));
  }
  public getCountry() {
    return sessionStorage.getItem(COUNTRY);
  }
  // BusinessTypeGroupList
  public saveBusinessTypeGroupListData(businessTypeGroupList) {
    window.sessionStorage.removeItem(businessTypeGroupListDATA);
    if (businessTypeGroupList !== null || businessTypeGroupList !== undefined) {
      window.sessionStorage.setItem(
        businessTypeGroupListDATA,
        JSON.stringify(businessTypeGroupList)
      );
    } else {
      window.sessionStorage.setItem(businessTypeGroupListDATA, null);
    }
  }
  // PROPERTY_SERVICE_DATA
  public saveServiceData(propertyServiceDTO: PropertyServiceDTO[]) {
    window.sessionStorage.removeItem(PROPERTY_SERVICE_DATA);
    if (propertyServiceDTO !== null || propertyServiceDTO !== undefined) {
      window.sessionStorage.setItem(
        PROPERTY_SERVICE_DATA,
        JSON.stringify(propertyServiceDTO)
      );
    } else {
      window.sessionStorage.setItem(PROPERTY_SERVICE_DATA, null);
    }
  }
  public getBusinessTypeListGroupData(): any[] {
    return JSON.parse(sessionStorage.getItem(businessTypeGroupListDATA));
  }
  // BusinessTypeList
  public saveBusinessTypeListData(businessTypeList) {
    window.sessionStorage.removeItem(businessTypeListDATA);
    if (businessTypeList !== null || businessTypeList !== undefined) {
      window.sessionStorage.setItem(
        businessTypeListDATA,
        JSON.stringify(businessTypeList)
      );
    } else {
      window.sessionStorage.setItem(businessTypeListDATA, null);
    }
  }

  public getBusinessTypeListData(): any[] {
    return JSON.parse(sessionStorage.getItem(businessTypeListDATA));
  }

  public saveSlotData(BusinessServiceTypes: BusinessServiceTypes[]) {
    window.sessionStorage.removeItem(SLOTDATA);
    if (BusinessServiceTypes !== null || BusinessServiceTypes !== undefined) {
      window.sessionStorage.setItem(
        SLOTDATA,
        JSON.stringify(BusinessServiceTypes)
      );
    } else {
      window.sessionStorage.setItem(SLOTDATA, null);
    }
  }

  public getSlotData(): BusinessServiceTypes[] {
    return JSON.parse(sessionStorage.getItem(SLOTDATA));
  }

  // Booking
  public saveBookingData(booking: Booking) {
    window.sessionStorage.removeItem(BOOKINGDATA);
    if (booking !== null || booking !== undefined) {
      window.sessionStorage.setItem(BOOKINGDATA, JSON.stringify(booking));
    } else {
      window.sessionStorage.setItem(BOOKINGDATA, null);
    }
  }

  public getBookingData(): Booking {
    return JSON.parse(sessionStorage.getItem(BOOKINGDATA));
  }

  clearHotelBooking() {
    window.sessionStorage.removeItem(BOOKINGDATA);
  }
  // Payment
  public savePaymentData(payment) {
    window.sessionStorage.removeItem(PAYMENT);
    if (payment !== null || payment !== undefined) {
      window.sessionStorage.setItem(PAYMENT, JSON.stringify(payment));
    } else {
      window.sessionStorage.setItem(PAYMENT, null);
    }
  }

  public getPaymentData(): Payment {
    return JSON.parse(sessionStorage.getItem(PAYMENT));
  }
  // Payment2
  public savePayment2Data(payment2) {
    window.sessionStorage.removeItem(PAYMENT2);
    if (payment2 !== null || payment2 !== undefined) {
      window.sessionStorage.setItem(PAYMENT2, JSON.stringify(payment2));
    } else {
      window.sessionStorage.setItem(PAYMENT2, null);
    }
  }

  public getPayment2Data(): Payment {
    return JSON.parse(sessionStorage.getItem(PAYMENT2));
  }
  // Property
  public savePropertyData(property) {
    window.sessionStorage.removeItem(PROPERTY);
    if (property !== null || property !== undefined) {
      window.sessionStorage.setItem(PROPERTY, JSON.stringify(property));
    } else {
      window.sessionStorage.setItem(PROPERTY, null);
    }
  }

  public getPropertyData(): BusinessUser {
    return JSON.parse(sessionStorage.getItem(PROPERTY));
  }
  // Booking City
  public saveBookingCity(bookingCity: string) {
    window.sessionStorage.removeItem(BOOKINGCITY);
    if (bookingCity !== null || bookingCity !== undefined) {
      window.sessionStorage.setItem(BOOKINGCITY, bookingCity);
    } else {
      window.sessionStorage.setItem(BOOKINGCITY, null);
    }
  }

  public getBookingCity(): string {
    return sessionStorage.getItem(BOOKINGCITY);
  }

  clearBookingCity() {
    window.sessionStorage.removeItem(BOOKINGCITY);
  }
  // Rooms Data
  public saveRoomsData(RoomsData: Room[]) {
    window.sessionStorage.removeItem(ROOMSDATA);
    if (RoomsData !== null || RoomsData !== undefined) {
      window.sessionStorage.setItem(ROOMSDATA, JSON.stringify(RoomsData));
    } else {
      window.sessionStorage.setItem(ROOMSDATA, null);
    }
  }

  public getRoomsData(): Room[] {
    return JSON.parse(sessionStorage.getItem(ROOMSDATA));
  }

  clearRoomsData() {
    window.sessionStorage.removeItem(ROOMSDATA);
  }
  //BusinessService
  public saveBusinessService(businessUser: BusinessUser[]) {
    window.sessionStorage.removeItem(BUSINESS_SERVICE);
    if (businessUser !== null || businessUser !== undefined) {
      window.sessionStorage.setItem(
        BUSINESS_SERVICE,
        JSON.stringify(businessUser)
      );
    } else {
      window.sessionStorage.setItem(BUSINESS_SERVICE, null);
    }
  }

  public getBusinessService(): BusinessUser[] {
    return JSON.parse(sessionStorage.getItem(BUSINESS_SERVICE));
  }
}
