import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient} from '@angular/common/http';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { AdminPanelLayoutComponent } from './layouts/adminPanel/AdminPanelLayout.component';
import { FrontendPanelLayoutComponent } from './layouts/frontendPanel/FrontendPanel.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

import { HeaderComponent } from './core/Header/Header.component';
import { FooterComponent } from './core/Footer/Footer.component';
import { MenuComponent } from './core/Menu/Menu.component';
import { SignInComponent } from './core/SignInPopup/SignIn.component';

import { AdminHeaderComponent } from './core/AdminHeader/AdminHeader.component';
import { AdminSidebarComponent } from './core/AdminSidebar/AdminSidebar.component';

import { MenuItems } from './core/Menu/menu-items';
import { AdminMenuItems } from './core/AdminHeader/admin-menu-items';
import { TokenStorage } from './token.storage';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import { GalleryModule } from 'ng-gallery';


const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
   // Change this to your upload POST address:
    url: 'https://httpbin.org/post',
    maxFilesize: 50,
    acceptedFiles: 'image/*'
  };
  const cookieConfig:NgcCookieConsentConfig = {
    cookie: {
      domain: environment.appUrl // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
    },
    
      position: "bottom-left",
      theme: "classic",
      palette: {
        popup: {
          background: "#E5E5E4",
          text: "#2B333F",
          link: "#2B333F"
        },
        button: {
          background: "#C95858",
          text: "#fff",
          border: "transparent"
        }
      },
      type: "opt-out",
      content: {
        message: "This website uses cookies to ensure you get the best experience on our website.",
        dismiss: "Got it!",
        deny: "Refuse cookies",
        link: "Learn more",
        href: environment.appUrl,
        policy: "Cookie Policy"
      }
    
  };
@NgModule({
  declarations: [
      AppComponent,
      AdminPanelLayoutComponent,
      FrontendPanelLayoutComponent,
      AuthLayoutComponent,
      HeaderComponent,
      FooterComponent,
      MenuComponent,
      SignInComponent,
      AdminHeaderComponent,
      AdminSidebarComponent,
  ],
  imports: [
      BrowserModule,
      HttpClientModule,
      BrowserAnimationsModule,
      Ng2TelInputModule,
    GalleryModule,
      NgbModule,
      DropzoneModule,
      AgmCoreModule.forRoot({
        apiKey: environment.googleKey
      }),
      RouterModule.forRoot(AppRoutes, {scrollPositionRestoration: 'enabled'})
  ],
  providers: [
      MenuItems, 
      AdminMenuItems,
      TokenStorage,
    { provide: LocationStrategy, useClass: HashLocationStrategy },

      {
        provide: DROPZONE_CONFIG,
        useValue: DEFAULT_DROPZONE_CONFIG
      }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
