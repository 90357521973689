import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgcCookieConsentService, NgcInitializeEvent, NgcStatusChangeEvent, NgcNoCookieLawEvent } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  //keep refs to subscriptions to be able to unsubscribe later
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;


  title = 'My Resorts Pune';
  constructor(){}

  ngOnInit() {
    // let cc = window as any;
    //    cc.cookieconsent.initialise({
    //     cookie: {
    //       domain: environment.appUrl // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
    //     },
        
    //       position: "bottom-left",
    //       theme: "classic",
    //       palette: {
    //         popup: {
    //           background: "#E5E5E4",
    //           text: "#2B333F",
    //           link: "#2B333F"
    //         },
    //         button: {
    //           background: "#C95858",
    //           text: "#fff",
    //           border: "transparent"
    //         }
    //       },
    //       type: "opt-out",
    //       content: {
    //         message: "This website uses cookies to ensure you get the best experience on our website.",
    //         dismiss: "Got it!",
    //         deny: "Refuse cookies",
    //         link: "Learn more",
    //         href: environment.appUrl,
    //         policy: "Cookie Policy"
    //       }
        
    //   });
    // // subscribe to cookieconsent observables to react to main events
    // this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
    //   () => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });

    // this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
    //   () => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });

    // this.initializeSubscription = this.ccService.initialize$.subscribe(
    //   (event: NgcInitializeEvent) => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });

    // this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
    //   (event: NgcStatusChangeEvent) => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });

    // this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
    //   () => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });

    //   this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
    //   (event: NgcNoCookieLawEvent) => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    // this.popupOpenSubscription.unsubscribe();
    // this.popupCloseSubscription.unsubscribe();
    // this.initializeSubscription.unsubscribe();
    // this.statusChangeSubscription.unsubscribe();
    // this.revokeChoiceSubscription.unsubscribe();
    // this.noCookieLawSubscription.unsubscribe();
  }

}
export const PARENTS_ORGANIZATION_ID = environment.parentOrganisationId;

export const API_URL_NZ = environment.nzAPIUrl;
export const WEBSITE_URL = environment.websiteUrl;
export const API_URL_IN = environment.inAPIUrl;
export const API_URL_LMS = environment.apiLms;
export const API_URL_PROMOTION = environment.apiUrlPromotion;
export const API_URL_ADDRESS = environment.apiUrlAddress;
export const APP_ID = environment.appId;
export const dashboardUrl = environment.dashboardUrl;
export const ANDROID_APP_URL = environment.androidAppUrl;
export const IOS_APP_URL = environment.iosAppUrl;
export const SMS_NUMBER = '+1 956 903 2629';
